import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/elements/404.vue')
    },
    {
        path: '/',
        name: 'HomeStartUp',
        component: () => import(/* webpackChunkName: "HomeStartUp" */ '../views/HomeStartUp.vue'),
    },
    {
        path: '/privacypolicy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/PrivacyPolicy.vue'),
    },
    {
        path: '/tos',
        name: 'TermsOfService',
        component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/Tos.vue'),
    },
];
  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
  
export default router;
